import React, { useEffect, useRef, useState } from "react"

import lottie from "lottie-web"


const HeaderAnimation = ({animationData}) => {

    let [isMobile, setIsMobile] = useState(false)
    // let isMobile = useRef()
    let [isVertical, setIsVertical]= useState(true)
    // let isVertical = useRef()
    let [isLaunching, setIsLaunching] = useState(false)

    let animationContainer = useRef()
    let animation = useRef()
    

    const desktopSegments = {
        ignition: [0, 10],
        loop: [11, 60],
        turnOff: [61, 79],
        launch: [80, 220],
        transition: [221, 260]
    }
    const mobileSegments = {
        ignition: [261, 270],
        loop: [271, 330],
        turnOff: [331, 339],
        launch: [340, 480],
        transition: [221, 260]
    }
    
    let [segments, setSegments] = useState(desktopSegments)
    

    useEffect(() => {

        animation.current = lottie.loadAnimation({
          container: animationContainer.current,
          renderer: "svg",
          loop: false,
          autoplay: false,
          animationData: animationData
        });

        

        // window.addEventListener('resize', () =>{

        //     clearTimeout(window.resizedFinished);
        //     window.resizedFinished = setTimeout(function() {

        //         console.log("window.innerWidth: ", window.innerWidth, "\nisMobile: ", isMobile.current, "\nisVertical: ", isVertical)
        // console.log("isLaunching: ", isLaunching)
                

        //         if(window.innerWidth <= 640 && isVertical.current === true){
        //             console.log("Entrou no if(window.innerWidth <= 640){ ")
        //             // setIsMobile(true)
        //             isMobile.current = true
        //             isVertical.current = false
        //             setSegments(mobileSegments)
        //             transitionToMobile()
        //         } else if(isVertical.current === false){
        //             console.log("Entrou no else")
        //             // setIsMobile(false)
        //             isMobile.current = false
        //             isVertical.current = true
        //             setSegments(desktopSegments)
        //             transitionToDesktop()
        //         }

        //     }, 500);

        // }, false)

        animation.current.addEventListener('DOMLoaded', () => {
            checkIfMobile()

        }, false)


    }, false);

    function checkIfMobile(){
        
        if(window.innerWidth <= 640 && isVertical){
            console.log("Entrou no if(window.innerWidth <= 640){ ")
            setIsMobile(true)
            setIsVertical(false)
            setSegments(mobileSegments)
            animation.current.goToAndStop(260, true)
        } else if( !isVertical ){
            console.log("Entrou no else")
            setIsMobile(false)
            isMobile.current = false
            setIsVertical(true)
            setSegments(desktopSegments)
            animation.current.goToAndStop(0, true)
        }
    }
    
    function ignition(){
        if(isLaunching) return

        console.log("Handle enter")
        animation.current.loop = true;
        animation.current.playSegments([segments.ignition, segments.loop], true);
    }
    function turnOff(){
        if(isLaunching) return
        
        console.log("Handle leave")
        animation.current.loop = false;
        animation.current.playSegments(segments.turnOff, true);
    }
    function launch(){

        if(isLaunching){
            return
        } else {
            setIsLaunching(true)
            setTimeout(()=>{ 
                setIsLaunching(false) 
                turnOff()
            }, 4000)
        }

        animation.current.loop = false;
        animation.current.playSegments(segments.launch, true);
    }


    function transitionToMobile(){
        animation.current.loop = false;
        animation.current.playSegments(segments.transition, true);
    }
    function transitionToDesktop(){
        animation.current.loop = false;
        animation.current.playSegments(segments.launch, true);
    }

    function handleHeaderAnimClick(e){
        e.preventDefault()
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'buttonClick',
          'category': 'Animation click',
          'action': 'click',
          'label': 'Header animation clicked'
        })
        launch()
      }


    
    return (
        <div ref={animationContainer} className="interactive-animation" 
            onClick={handleHeaderAnimClick}
            onMouseEnter={ignition}
            onMouseLeave={turnOff}
        ></div>
    )

}

export default HeaderAnimation